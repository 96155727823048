.nfc-icon {
    background: url(nfc.svg) no-repeat;
    background-size: contain;
    height: 50px;
}

a:hover .nfc-icon  {
    background: url(nfc-white.svg) no-repeat;
    background-size: contain;
    height: 50px;
}

.active .nfc-icon  {
    background: url(nfc-white.svg) no-repeat;
    background-size: contain;
    height: 50px;
}

.content-state-action-intercom {
    display: inline-flex;
    gap: 2em;

    .state-description {
        margin: auto;

        span {
            white-space: nowrap;
        }
    }
}

